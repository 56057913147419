/* Fonts  */
@font-face {
    font-family: 'Almarai';
    src: url(../fonts/Almarai/Almarai-Regular.ttf);
}

@font-face {
    font-family: 'Almarai_Bold';
    src: url('../fonts/Almarai/Almarai-Bold.ttf');
}

@font-face {
    font-family: 'Almarai_ExtraBold';
    src: url('../fonts/Almarai/Almarai-ExtraBold.ttf');
}

@font-face {
    font-family: 'Almarai_Light';
    src: url('../fonts/Almarai/Almarai-Light.ttf');
}

/* .content-wrapper {
    background-image: url('../images/pink-background.png');
    background-repeat: no-repeat;
    background-position: right 0px;
    background-size: contain;
    animation: animateBackground 1s ease-in .1s 1;
    transition: .1s;
} */

.bg-img.pink {
    clip-path: polygon(93% 0%, 100% 0%, 100% 100%, 46% 100%);
    /* height: 700px; */
    height: 100%;
    background: linear-gradient(to right,#f5ede3,#fff);
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
}

.bg-img.formBg {
    clip-path: polygon(90% 0%, 100% 0%, 100% 100%, 53% 100%);
    height: 100%;
    background-color: rgba(245, 237, 227, .5);
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

.bg-img.silver {
    clip-path: polygon(93% 0%, 100% 0%, 100% 100%, 46% 100%);
    height: 100%;
    background: linear-gradient(to right, #DBDDE2 , white);
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
}
.social_media img:hover{
    filter: invert(77%) sepia(57%) saturate(1673%) hue-rotate(
        365deg) brightness(87%) contrast(88%);
}
@keyframes animateBackground {
    0% {
        background-position: right 660px;
    }
    100% {
        background-position: right 0px;
    }
}

/* Body */
body {
    font-family: 'Almarai', 'sans-serif';
    overflow-x: hidden!important;
}

/* navbar */
.navbar {
    padding-top: 20px;
    /* padding-right: 20px; */
    /* z-index: 9999; */
    /* position: relative; */
}

.nav-link {
    color: #101E40!important;
    font-size: .9375rem;
}

.navbar-nav .nav-item {
   margin-left: 12px;
}

.navbar-nav .nav-link {
   border-bottom: 2px solid transparent;
   padding-top: 4px;
   display: inline-block;
}

.navbar-nav .nav-link:hover {
    color: #BA8747!important;
}

.navbar-nav .nav-item.active .nav-link {
    color: #BA8747!important;
    border-bottom-color: #BA8747;
    text-shadow: 0px 6px 6px rgb(0 0 0 / 16%);
}

.navbar-brand {
    margin-left: 36px;
    z-index: 9999;
}

/* Headings */
h1, h2, h3 {
    color: #ba8747;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-shadow: 0px 12px 12px rgb(0 0 0 / 16%);
}

h3 {
    font-size: 1.6875rem;
}

h1 img, h2 img, h3 img {
    width: 40px;
    image-rendering: -webkit-optimize-contrast;
    position: relative;
    top: 3px;
}

/* h1, h2, h3::before { */
    /* background-image: url('../icons/heading_icon.svg');
    display: block;
    content: "";
    width: 100%;
    height: 50px;
    background-position: right;
    background-repeat: no-repeat;
    float: right; */

    /* position: relative;
    top: 55px;
    left: 55px; */

    /* position: absolute;
    top: 5px;
    left: 20px; */
/* } */

h5, h6 {
    color: #0A1F3F;
    font-weight: bold;
}

/* .section {
    padding-top: 60px;
    padding-bottom: 60px;
} */

.top-60 {
    position: relative;
    top: -60px;
}

section {
    padding-top: 40px;
}

/* Paragraph  */
p {
    color: #9FA5B2;
    font-size: 13px;
}

span {
    color: #ba8747;
    font-size: .9375rem;
}

.numbers {
    text-align: center;
}

.numbers h4 span {
    font-size: 4.5rem;
    font-family: 'Almarai_ExtraBold', 'sans-serif';
    margin-bottom: 0;
    color: #0A1F3F;
}

.numbers h4 {
    font-size: 4.5rem !important;
    font-family: 'Almarai_ExtraBold', 'sans-serif';
    margin-bottom: 0;
    color: #0A1F3F;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
    transition-duration: 0s!important;
}

/* buttons  */

.btn {
    font-size: .9375rem;
    transition: none;
}

.btn-black {
    background-color: #101E40;
    color: white;
    border-radius: 20px;
    padding: 6px 20px;
    font-size: 14px;
    /* transition: .5s; */
}

.swal-button {
    background-color: #101E40!important;
    color: white!important;
    border-radius: 20px!important;
    padding: 6px 30px!important;
    font-size: 14px!important;
}

.swal-footer {
    text-align: center!important;
}

.btn-black.sm {
    padding: 6px 45px;
}

.btn-black.with-arrow {
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.5' height='12.565' viewBox='0 0 12.5 12.565'%3E%3Cpath id='arrow-8' d='M17,10.9H9.4l3.3-3.3a.99.99,0,1,0-1.4-1.4l-5,5a.967.967,0,0,0,0,1.4h0l5,5a.99.99,0,1,0,1.4-1.4L9.4,12.9H17a.945.945,0,0,0,1-1A1,1,0,0,0,17,10.9Z' transform='translate(-5.75 -5.585)' fill='%23fff' stroke='%23101e40' stroke-width='0.5'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: -20px center; */
    transition: none;
}

.left-arrow img {
    width: 12px!important;
}

.btn-brown.with-arrow {
    padding: 6px 20px 6px 40px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.5' height='12.565' viewBox='0 0 12.5 12.565'%3E%3Cpath id='arrow-8' d='M17,10.9H9.4l3.3-3.3a.99.99,0,1,0-1.4-1.4l-5,5a.967.967,0,0,0,0,1.4h0l5,5a.99.99,0,1,0,1.4-1.4L9.4,12.9H17a.945.945,0,0,0,1-1A1,1,0,0,0,17,10.9Z' transform='translate(-5.75 -5.585)' fill='%23fff' stroke='%23ba8748' stroke-width='0.5'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: -20px center;
    transition: none;
}

.btn-brown {
    background-color: #BA8748;
    color: white;
    border-radius: 20px;
    /* padding-left: 15px;
    padding-right: 15px; */
    padding: 6px 45px 6px 20px;
    box-shadow: 0 12px 6px rgb(0 0 0 / 16%);
}

.btn-black:hover {
    color: white;
    background-color: #BA8748;
    box-shadow: 0 12px 6px rgb(0 0 0 / 16%);
}

.btn-black.active {
    color: white;
    background-color: #BA8748;
    box-shadow: 0 12px 6px rgb(0 0 0 / 16%);
}

.btn-black:hover img {
    filter: invert(0%) sepia(0%) saturate(100%) hue-rotate(100deg) brightness(150%) contrast(100%);
}

.btn-black img {
    width: 20px;
}

.arrow-down img {
    width: 12px;
}

.btn-brown:hover {
    color: white;
    opacity: .8;
}

.btn-brown img {
    padding-right: 0px;
    width: 12px!important;
}

.btn-slider {
    /* background-color: #101E40;
    color: white; */
    /* transition: .5s; */
    /* border-radius: 50%; */
    /* transform: translate(0px, 40px); */
    position: relative;
    z-index: 99;
    right: 140px;
}

/* .btn-slider.cross {
    top: 30px;
    right: 140px;
    background-color: #101E40;
    color: white;
    border-radius: 50%;
} */

.btn-slider:hover {
    transition: .5s;
}

/* social media  */
.social_media {
    padding-left: 10px;
}

.social_media span {
    padding-left: 16px;
}

.social_media span:last-child {
    padding-left: 0;
}

/* clients */
.clients {
    padding-top: 60px;
    padding-bottom: 60px;
}

.clients img {
    width: 60px;
}

.heading-extend span {
    color: #091E3E;
    font-size: unset;
}

.heading-extend .arrow-gold {
    margin: 0 20px 0 10px;
    width: 20px;
}

/* Form */
label {
    font-family: 'Almarai_Bold', 'sans-serif';
    font-size: .9375rem;
}

form .form-control {
    border-radius: 2rem;
    border-width: .3rem;
    font-size: .6875rem;
}

form .form-control::placeholder {
    color: #9FA5B2;
}


/* Slick Slider */
.slick_slider .slick-next:before, .slick_slider .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 0.99;
    z-index: 1;
    color: #BA8748;
    position: absolute;
}

[dir=rtl] .slick-next:before {
    content: url(../icons/slider-prev.svg);
    left: 30px;
}
[dir=rtl] .slick-prev {
    z-index: 1;
}

[dir=rtl] .slick-prev:before {
    content: url(../icons/slider-next.svg);
    right: 30px;
}

.slick_slider .nav-link {
    border-radius: 12px;
    border: unset;
    background-color: #E3E9EC;
    color: #ffffff!important;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.slick_slider .nav-link.active {
    background-color: #BA8748;
}

.slick_slider .nav-tabs {
    border-bottom: 0;
}

.slick_slider .card {
    border: none;
    text-align: center;
}

.slick_slider .card img {
    border: 4px solid #E3E9EC;
    width: 130px;
    height: 130px;
    margin: auto;
    display: block;
    object-fit: contain;
}

.slick_slider .card-text {
    color: #ba8747;
    font-size: 15px;
}

/* Road Map Section */

.road-map {
    text-align: center;
    padding-top: 100px;
}

.road-map .main {
    text-align: center;
    position: relative;
}

.road-map span {
    color: #9FA5B2;
    font-size: 11px;
}

.box-1 {
    position: absolute;
    top: -60px;
    left: 180px;
}

.box-1 .button {
 
    padding: 12px;
    /* margin-top: -40px; */
}

.box-2 {
    position: absolute;
    bottom: 0;
    left: 180px;
}

.box-2 span {
    position: relative;
    bottom: -70px;
    left: 100px;
}

.box-2 .button {
    position: relative;
    left: 150px;
    width: 180px;
    bottom: 40px;
}

.main .mid-img {
    position: absolute;
    left: 320px;
    top: -120px;
    z-index: 0;
    width: 36.6%;
}

.mid span {
    position: relative;
    top: -340px;
    right: -130px;
}

.mid .top {
    position: relative;
    top: -460px;
    z-index: 9999;
    right: -100px;
}

.box-3 {
    position: absolute;
    top: 148px;
    right: 160px;
}

.box-3 .button {
    position: relative;
    top: -33px;
    left: 80px;
    z-index: 0;
    padding: 10px;
    /* background: #fefdfb; */
}

.position-diary {
    position: relative;
    bottom: 10px;
}

.form-process .carousel-indicators {
    justify-content: right!important;
    /* margin-right: 23%; */
    width: 150px;
    bottom: 100px;
    margin-right: 0 !important;
    right: 16%;
}

.form-process .carousel-indicators [data-bs-target] {
    background-color: silver;
 }

.form-process .carousel-indicators .active {
   background-color: #BA8748;
}

.form-process .carousel-control-prev, .form-process .carousel-control-next {
    display: none;
}

.form-process .carousel-indicators button {
    pointer-events: none;
}

.form-process .btn-slider {
    /* top: 50px; */
    top: 0;
}

.form-process form {
    padding-top: 10px;
}

.p-75 {
    position: absolute!important;
    bottom: 75px!important;
}

.p-14 {
    position: absolute!important;
    bottom: 14px!important;
}

.r-25 {
    right: 25px!important;
}

.r-12 {
    position: relative;
    right: -12px!important;
}

.form-process .form-check label {
    border: 4px solid #E3E9EC;
    border-radius: 20px;
    width: 330px;
    position: relative;
    cursor: pointer;
    transition: .3s;
}

.form-process input[type="radio"] {
    visibility: hidden;
}

.form-process .content {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.form-process .content h6 {
    font-size: 15px;
}

.form-process .content span {
    font-size: 11px;
    color: #9FA5B2;
    font-family: 'Almarai_Light', 'sans-serif';
}

.form-process .form-check {
    margin-bottom: 2rem;
}

.form-process .form-check:nth-child(2n) {
    margin-right: 13rem;
}

.form-process .third .form-check {
    margin-right: 0;
}

.form-process .third .form-check:nth-child(odd) {
    margin-right: 13rem;
}

.form-process .fourth .form-check {
    margin-right: 0;
}

.form-process .fourth .form-check:nth-child(odd) {
    margin-right: 13rem;
}

.custom-hit {
    min-height: 450px;
}

.radio-form .form-check img {
    position: absolute;
    width: 70px;
    margin-right: 20px;
    bottom: -10px;
}

/* .radio-form .next {
    position: absolute;
    bottom: 30px;
} */

.number {
    background-image: url(../images/middle-img.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.set-position {
    /* position: relative;
    right: 50px; */
    margin-right: -40px;
}

/* .carousel-text {
    position: absolute;
    bottom: 40px;
    right: 330px;
} */

.card-text p {
    position: relative;
    bottom: 40px;
    right: 220px;
}

.btn-up {
    position: absolute;
    /* bottom: 75px; */
    bottom: 35px;
}

.form-process .form-check-label.active {
    border-color: #BA8748;
    box-shadow: 0px 12px 12px rgba(0,0,0,16%);
    background: white;
}

/* Form Process radio button images */
.briefcase {
    margin-right: 5px;
}

.lamp {
    margin-right: 12px!important;
}

.file-holder {
    margin-right: 10px!important;
}

.cordless {
    width: 60px!important;
    bottom: -20px!important;
    right: 5px!important;
    position: relative!important;
}

.headphones {
    width: 60px!important;
    top: 25px!important;
    position: relative!important;
}

.recorder {
    width: 65px!important;
    bottom: -25px!important;
    right: 5px!important;
    position: relative;
}

.voice-recorder {
    position: relative;
    width: 62%;
    background-color: #E3E9EC;
    border-radius: 10px;
    left: -105px;
    margin: 30px 0px 0;
}

.recording {
    width: 0;
    background-color: #BA8748;
    height: 8px;
    border-radius: 10px;
    margin-right: auto;
}

.playing {
    width: 0;
    background-color: #0A1F3F;
    height: 8px;
    border-radius: 10px;
    margin-right: auto;
}

.play-time {
    margin: 16px 0px 120px;
}

.play-time span {
    color: #0A1F3F;
    font-size: 12px;
    font-weight: 600;
}

.pencils {
    position: relative!important;
    width: 170px!important;
    top: 25px!important;
}

.building {
    top: 20px;
    width: 100px!important;
    right: -20px;
}

.recorder-btn {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.999' height='21.427' viewBox='0 0 14.999 21.427'%3E%3Cpath id='microphone-2' d='M12.5,17a4.29,4.29,0,0,0,4.285-4.285V6.285a4.285,4.285,0,1,0-8.571,0v6.428A4.29,4.29,0,0,0,12.5,17ZM20,12.714V10.571a1.071,1.071,0,1,0-2.143,0v2.143a5.357,5.357,0,0,1-10.714,0V10.571a1.071,1.071,0,1,0-2.143,0v2.143a7.5,7.5,0,0,0,6.428,7.414v1.157H8.214a1.071,1.071,0,1,0,0,2.143h8.571a1.071,1.071,0,0,0,0-2.143H13.571V20.127A7.5,7.5,0,0,0,20,12.714Z' transform='translate(-5 -2)' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px 56px!important;
    background-color: #BA8748!important;
    margin-top: 20px;
    /* position: absolute;
    bottom: 30px; */
}

.notes-btn {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.999' height='21.427' viewBox='0 0 20.999 21.427'%3E%3Cg id='writing' transform='translate(-3.24 -3)'%3E%3Cpath id='Path_114' data-name='Path 114' d='M17.882,19.553l-2.357.482a2.442,2.442,0,0,1-2.25-.679,2.474,2.474,0,0,1-.679-2.232l.491-2.4a2.45,2.45,0,0,1,.67-1.259c5.952-5.9,5.8-5.927,6.482-6.312V5.625A2.621,2.621,0,0,0,17.623,3H5.865A2.629,2.629,0,0,0,3.24,5.625V21.8a2.629,2.629,0,0,0,2.625,2.625H17.623A2.621,2.621,0,0,0,20.239,21.8v-4l-1.071,1.071A2.409,2.409,0,0,1,17.882,19.553ZM7.7,6.571h8.071a.893.893,0,0,1,0,1.786H7.7a.893.893,0,1,1,0-1.786Zm2.428,14.285H7.7a.893.893,0,1,1,0-1.786h2.428a.893.893,0,0,1,0,1.786Zm0-4.16H7.7a.893.893,0,0,1,0-1.786h2.428a.893.893,0,0,1,0,1.786Zm1.482-4.169H7.7a.893.893,0,1,1,0-1.786h3.91a.893.893,0,1,1,0,1.786Z' fill='%23fff'/%3E%3Cpath id='Path_115' data-name='Path 115' d='M24.97,9.638a2.042,2.042,0,0,0-2.884,0c-5.9,5.959-5.855,5.717-5.925,6.092l-.487,2.387a.7.7,0,0,0,.822.822l2.387-.487c.369-.068.1.008,6.092-5.925A2.043,2.043,0,0,0,24.97,9.638Z' transform='translate(-1.332 -0.648)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px 56px!important;
    background-color: #BA8748!important;
    margin-top: 20px;
    /* position: absolute;
    bottom: 30px; */
}

.voice-recording {
    text-align: center;
    padding-top: 30px;
}

.delete {
    background: #BA4848;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.847' height='15.848' viewBox='0 0 15.847 15.848'%3E%3Cpath id='Path_111' data-name='Path 111' d='M17.054,5.169H13.093V3.846A1.917,1.917,0,0,0,11.112,2H8.735A1.917,1.917,0,0,0,6.754,3.846V5.169H2.792a.792.792,0,1,0,0,1.585h.792v8.716a2.377,2.377,0,0,0,2.377,2.377h7.923a2.377,2.377,0,0,0,2.377-2.377V6.754h.792a.792.792,0,1,0,0-1.585ZM8.339,13.092a.792.792,0,1,1-1.585,0V9.923a.792.792,0,0,1,1.585,0Zm0-9.247c0-.127.166-.261.4-.261h2.377c.23,0,.4.135.4.261V5.169H8.339Zm4.754,9.247a.792.792,0,1,1-1.585,0V9.923a.792.792,0,0,1,1.585,0Z' transform='translate(-2 -1.999)' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px 56px;
    border-radius: 20px;
    box-shadow: 0px 12px 6px rgba(0, 0, 0, 16%);
}

.play {
    background-color: #51BA48;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.307' height='14.998' viewBox='0 0 12.307 14.998'%3E%3Cpath id='play-7' d='M8,7.033V19.6a1.211,1.211,0,0,0,1.868,1.019l9.875-6.284a1.213,1.213,0,0,0,0-2.05L9.868,6.014A1.211,1.211,0,0,0,8,7.033Z' transform='translate(-8 -5.819)' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px 56px;
    border-radius: 20px;
    box-shadow: 0px 12px 6px rgba(0, 0, 0, 16%);
}

.stop {
    background-color: #101E40;
    background-image: url(../icons/stop.png);
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px 56px;
    border-radius: 20px;
    position: relative;
    left: -110px;
    top: -40px;
}

.player-stop {
    background-color: #101E40;
    background-image: url(../icons/stop.png);
    background-repeat: no-repeat;
    background-position: center;
    /* padding: 14px 45px; */
    padding: 16px 56px;
    border-radius: 20px;
}

.actions {
    /* margin-left: 65px; */
    position: absolute;
    /* left: 90px; */
    bottom: 14px;
}

.actions .btn {
    margin-right: 30px;
}

.actions .btn:first-child {
    margin-right: 0;
}

.drop-shadow {
    box-shadow: 0px 12px 6px rgba(0, 0, 0, 16%);
}

.text-area {
    margin-right: 80px;
}

.text-area textarea {
    border: 3px solid #E3E9EC;
    border-radius: 16px;
    resize: none;
}

textarea::placeholder {
    font-size: 15px;
    color: #9FA5B2!important;
}

/* .submit-btn {
    margin-top: 100px;
} */

.submitted-page h5 {
    font-size: 100px;
    font-family: 'Almarai_ExtraBold', 'sans-serif';
    margin: 50px 0;
}

.submitted-page .content h6 {
    font-size: 15px;
    font-weight: bold;
    margin-top: .5rem;
}

.submitted-page .content p {
    font-size: 15px;
    color: #BA8748;
    margin-bottom: 0;
}

.border-gold {
    border-color: #BA8748 !important;
}

.border-red {
    border-color: #DD0000 !important;
}

/* .email, .phone {
    direction: ltr;
} */

.email-error {
    color: #DD0000;
    position: relative;
    right: 30px;
}

.caution {
    color: #DD0000;
    position: relative;
    top: 20px;
    font-weight: bold;
}

.progress-bar-brown {
    background-color: #BA8748!important;
}

.progress-bar-black {
    background-color: #0A1F3F!important;
}

/* .email::placeholder {
    direction: rtl;
    text-align:end ;
} */

.border-grey {
    border-color: grey;
}

.form-control:focus {
    border-color: #ced4da;
    box-shadow: none;
}

.carousel-text {
    position: relative;
    /* left: 0; */
    /* margin: auto; */
    bottom: 80px;
    left: -225px;
    display: inline-block;
    z-index: 2;
    width: 200px;
}

.carousel-text p {
    color: #707070;
}

.main .mid-img {
    position: absolute;
    left: 310px;
    top: -110px;
    z-index: -1;
    width: 300px;
}

.main .mid-img.rod1 {
    width: 400px;
    animation: rotateAntiClock 5s linear 0s infinite;
}

img.img-fluid.mid-img.rod2 {
    width: 250px;
    left: 445px;
    top: -95px;
    z-index: 9999;
    animation: rotateClock 5s linear .5s infinite;
}

img.img-fluid.mid-img.rod3 {
    z-index: 98;
    width: 150px;
    left: 490px;
    top: -40px;
    animation: rotateClock 5s linear .5s infinite;
}

img.img-fluid.mid-img.rod4 {
    z-index: 98;
    width: 210px;
    left: 390px;
    top: 150px;
    z-index: -1;
}

img.img-fluid.mid-img.rod5 {
    left: 340px;
    top: -20px;
    animation: rotateAntiClock 5s linear .5s infinite;
}

.rdtPicker td, .rdtPicker th {
    text-align: center;
    height: 20px!important;
}

.slick-slider {
    overflow: hidden;
    height: auto;
}

.slick-next, .slick-prev {
    top: 35%;
}

.progress-bar {
    height: 4px;
    background-color: black;
    border-radius: 30px;
    margin-left: auto;
 
  }

  .main-progress {
    position: relative;
    bottom: 300px;
    text-align: center;
    justify-content: center;
    width: 20%;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: none;
  }

  /* .hide-on-load {
    opacity: 0;
} */

.mt-25 {
    position: relative;
    top: -18px;
}

.navbar-toggler, .navbar-toggler:focus {
    box-shadow: none;
}

.footer-wrap p {
    text-align: center!important;
}
section.form-process {
    /* max-width: 1680px; */
    margin: 0 auto;
}

.submitted-data {
    text-align: right;
    direction: ltr;
}

.time {
    direction: ltr;
    text-align: end;
}

.audio-error {
    position: absolute;
    bottom: -80px;
    font-size: 12px;
    color: #dc3545;
    left: 0;
    right: 130px;
}

.disabled {
    pointer-events: none;
}

#nav-tabContent {
    margin-top: 30px;
}

#nav-tabContent .card {
    overflow: hidden;
}

.slick-list {
    margin: 0px 80px;
}

.pdf-link {
    width: 100%;
    position: absolute;
    bottom: -10px;
    color: #9FA5B2;
    z-index: 9999;
    font-size: 12px;
}

.pdf-link a {
    color: #ba8747;
}

@media only screen and (max-width: 1199px) {

    .pdf-link {
        width: 100%;
        position: absolute;
        bottom: -30px;
        color: #9FA5B2;
    }

    .txt-delete {
        left: 180px!important;
    }

    .btn-up {
        position: relative;
        bottom: 10px;
        right: 0;
        /* z-index: 1; */
    }

    .form-process .form-check:nth-child(2n) {
        margin-right: 0;
    }

    .custom-hit {
        min-height: 550px;
    }

    .box-1 {
        top: -60px;
        left: 90px;
    }

    .box-2 {
        position: absolute;
        bottom: 0;
        left: 90px;
    }

    .actions {
        margin-left: 0;
    }

    .voice-recorder {
        width: 335px;
        left: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .form-process .fourth .form-check:nth-child(odd) {
        margin-right: 2.2rem;
    }

    .box-3 .button {
        position: relative;
        top: 15px;
        left: 70px;
        z-index: 0;
        padding: 10px;
    }

    .box-3 {
        position: absolute;
        top: 100px;
        right: 80px;
    }

    .main .mid-img {
        left: 230px;
        top: -120px;
        width: 450px;
    }

    img.img-fluid.mid-img.rod5 {
        left: 250px;
        top: -20px;
        width: 350px;
    }

    img.img-fluid.mid-img.rod3 {
        left: 400px;
        top: -50px;
    }

    img.img-fluid.mid-img.rod2 {
        left: 355px;
    }

    .main .mid-img.rod1 {
        left: 200px;
        top: -100px;
        width: 450px;
    }

    .mid span {
        right: -170px;
    }

    .mid .top {
        right: -100px;
    }

    footer {
        position: relative;
        right: 0;
        left: 0;
        bottom: 0;
        /* padding-bottom: 20px; */
        z-index: 1;
    }

    .carousel-text {
        position: absolute;
        right: 25%;
    }

    .carousel-text p {
        display: inline-block;
    }

    .carousel-indicators {
        z-index: 0;
    }

}

@media only screen and (max-width: 991px) {

    .pdf-link {
        bottom: -40px;
        text-align: right;
        margin-right: -40px;
    }

    .radio-text {
        margin-top: 20px;
    }
    
    .audio-error {
        bottom: -50px;
        font-size: 10px;
        left: 0;
        right: 0;
    }

    .txt-delete {
        left: 70px!important;
    }

    .box-1 {
        left: 0;
    }

    .box-2 {
        position: absolute;
        bottom: 0;
        left: 0px;
    }

    h4 {
        font-size: 2.9rem!important;
    }

    .play, .delete {
        padding: 14px 45px;
    }

    .actions {
        margin-left: 0;
    }

    .actions .btn {
        margin-right: 20px;
    }

    .btn-brown {
        padding: 3px 29px 3px 10px;
    }

    .form-process .form-check label {
        width: 330px!important;
    }

    .box-3 {
        top: 100px;
        right: 30px;
    }

    .main .mid-img.rod1 {
        left: 110px;
    }

    img.img-fluid.mid-img.rod2 {
        width: 220px;
        left: 245px;
        top: -80px;
        z-index: 9999;
    }

    img.img-fluid.mid-img.rod3 {
        width: 150px;
        left: 280px;
        top: -40px;
    }

    img.img-fluid.mid-img.rod5 {
        left: 170px;
        top: -20px;
        width: 290px;
    }

    img.img-fluid.mid-img.rod4 {
        width: 190px;
        left: 230px;
        top: 150px;
    }

    .mid .top {
        top: -400px;
        right: -90px;
    }

    .mid span {
        top: -230px;
        right: -160px;
    }

    .btn-slider {
        right: 50px;
    }

    .navbar-nav {
        background-color: white;
    }

    .carousel-text {
        position: absolute;
        bottom: 40px;
        right: 19.5% !important;
    }

    .form-process .third .form-check:nth-child(odd) {
        margin-right: 0;
    }

    .form-process .first .carousel-indicators {
        bottom: -7px!important;
    }

    .form-process .first .carousel-text {
        bottom: 23px!important;
    }

    .nav-container {
        padding-right: 0;
    }

    #root {
        overflow-x: hidden;
    }

    .social_media {
        padding-right: 36px;
        padding-top: 10px;
    }

    h3 {
        font-size: 1.5rem;
    }

    .card-body {
        flex: 1 1 auto;
        padding: 1rem 0.8rem;
    }

    .card-title {
        font-size: .8rem;
    }

    .slick_slider .card-text {
        font-size: 10px;
    }

    .slick-slider {
        overflow: unset;
    }

    [dir=rtl] .slick-prev:before {
        right: 25px;
        top: 40px;
    }

    [dir=rtl] .slick-next:before {
        left: 10px;
    }
}



@media only screen and (max-width: 991px) {
    .slick-list {
        margin: 0;
    }

    .box-3 .button {
        position: relative;
        top: -22px;
        left: 70px;
        z-index: 0;
        padding: 10px;
    }

}

@media only screen and (max-width: 767px) {

    .slick-next, .slick-prev {
        top: 50%;
    }

    .pdf-link {
        font-size: 12px;
        bottom: 75px;
        text-align: center;
        left: 0;
    }

    .slick-list {
        margin: 0;
    }

    .caution {
        top: 0;
    }

    .radio-text {
        margin-top: 20px;
    }

    .audio-error {
        bottom: -50px;
        font-size: 10px;
        left: 0;
        right: 0;
    }

    .submitted-page h5 {
        font-size: 70px;
        margin: 0;
    }

    .txt-delete {
        padding: 14px 48px!important;
        top: -5px!important;
        left: 0!important;
    }

[dir=rtl] .slick-next:before {
    left: 25px;
    top: 40px;
}

.bg-img{
    bottom: -3px;
}
    .navbar {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }

    section.form-process {
        padding-top: 150px;
    }

    section {
        padding-top: 80px;
    }

    .r-12 {
        right: 5px!important;
    }

    .submitted-page .content h6, .submitted-data, .submitted-page .content p {
        text-align: center;
    }

    .one img, .form-process img.number {
        z-index: -2;
        position: relative;
    }

    .slick_slider .nav-link {
      font-size: 11px;
      /* width: 100%; */
    }
    .slick_slider .card {
        background-color: transparent;
    }

    /* .slick_slider .nav-tabs {
        display: block!important;
    } */

    .card-body {
        padding: 1rem 0.8rem;
    }

    .card-title {
        font-size: .8rem;
        text-align: center;
    }

    .slick_slider .card-text {
        font-size: 10px;
        text-align: center;
    }

    .third .next {
        margin: auto;
        display: block;
    }

    .p-14 {
        position: relative!important;
        bottom: 0!important;
    }

    .position-diary {
        bottom: 0;
    }

    .bottom-with-50 {
        bottom: 0;
    }

    .clients img {
        margin: auto;
        display: block;
        padding-bottom: 10px;
    }

    .main-progress {
        width: 60% !important;
        position: fixed;
        bottom: 50%;
    }

    .text-at-center {
        text-align: center;
    }

    .body-content p {
        text-align: justify;
    }

    .road-map {
        padding-top: 0;
        margin-bottom: 0;
        height: 85vh;
        display: flex;
        align-items: center;
    }

    .navbar-toggler {
        position: relative;
        bottom: 50px;
    }

    .bg-img.pink, .bg-img.silver {
        clip-path: polygon(93% 0%, 100% 0%, 100% 100%, 0% 100%);
    }

    .left-arrow img {
        width: 7px!important;
    }

    .notes-btn, .recorder-btn {
        margin: auto;
        display: block;
    }

    .form-writing .btn {
        margin: auto!important;
        display: block;
        top: 33px;
    }

    div#root {
        overflow-x: hidden;
    }

    .stop {
        left: 0;
        right: 0;
        top: 10px;
    }

    .content-wrapper {
        background-size: 100% 100%;
    }

    .navbar-nav .nav-link {
        padding-top: 8px;
    }

    /* buttons */
    .btn {
        font-size: 10px;
    }

    .btn-slider {
        font-size: unset;
    }

    .box-1 {
        top: -22px;
        left: 5px;
    }

    .box-1 img {
        width: 70px;
        position: relative;
        bottom: 5px;
    }

    .box-1 .button {
        padding: 3px 0;
        margin-top: -15px;
    }

    .box-2 {
        position: absolute;
        bottom: -10px;
        left: 0px;
    }

    .box-2 .button {
        position: relative;
        left: 50px;
        width: 110px;
        bottom: 25px;
        height: 22px;
    }

    .btn-black.sm {
        padding: 6px 30px;
    }

    .box-2 .content {
        position: relative;
        bottom: 20px;
    }

    .box-2 .content img {
        width: 70px;
    }

    .box-2 span {
        position: relative;
        bottom: -40px;
        left: 60px;
    }

    .main .mid-img {
        left: 90px;
        top: -50px;
        width: 160px;
    }

    img.img-fluid.mid-img.rod3 {
        width: 50px;
        left: 130px;
        top: -20px;
    }

    img.img-fluid.mid-img.rod2 {
        width: 70px;
        left: 120px;
        top: -30px;
    }

    img.img-fluid.mid-img.rod5 {
        left: 100px;
        width: 80px;
        top: -10px;
        z-index: 1;
    }

    .main .mid-img.rod1 {
        width: 100px;
        left: 100px;
        top: -33px;
    }

    img.img-fluid.mid-img.rod4 {
        width: 60px;
        left: 110px;
        top: 50px;
    }

    .mid .top {
        position: absolute;
        top: -25px;
        z-index: 9999;
        right: 90px;
    }

    .mid span {
        top: -140px;
        right: -60px;
    }

    .box-3 {
        position: absolute;
        top: 20px;
        right: 10px;
    }

    .box-3 img {
        width: 60px;
        position: relative;
        top: 0px;
        left: 0px;
    }

    .box-3 .button {
        position: relative;
        top: 15px;
        left: 40px;
        background: #fefcfb;
        z-index: 0;
        padding: 3px 0;
    }

    /* .btn-black {
        padding: 4px 10px;
    } */

    .second form .btn {
        margin-top: 0!important;
        margin: auto;
        display: block;
        margin-bottom: 40px;
    }

    .road-map span {
        font-size: 10px;
    }

    h4 {
        font-size: 2.5rem!important;
    }

    h3 {
        font-size: 1.3rem;
    }

    h1, h2, h3::before {
        top: 0;
    }

    p {
        font-size: 12px;
        text-align: justify;
    }

    span {
        font-size: 12px;
    }

    .clients {
        padding-top: 0;
    }

    .heading-extend span::before {
        margin: 0px 15px 0px 10px;
    }

    .form-process .form-check:nth-child(2n) {
        margin-right: 0;
    }

    .form-process .content h6 {
        font-size: 13px;
        text-align: center;
    }

    .form-process .content span {
        font-size: 10px;
        text-align: center;
        display: block;
        padding-right: 60px;
        padding-left: 60px;
    }

    .radio-form .form-check img {
        width: 50px;
    }

    .pencils {
        position: absolute!important;
        width: 70px!important;
    }

    .custom-hit {
        /* min-height: 1050px; */
        min-height: 950px;
    }

    .third .custom-hit {
        min-height: 860px;
    }

    .btn-up {
        position: relative;
        bottom: 0;
        z-index: 10000;
        display: block;
        margin: auto;
        margin-bottom: 30px;
    }

    .form-process .carousel-indicators, .form-process .first .carousel-indicators {
        justify-content: center!important;
        /* margin-right: 15%; */
        width: auto;
        bottom: -40px!important;
    }

    .carousel-text {
        top: 0px;
        right: 40%!important;
        position: relative;
    }

    .form-process form {
        padding-top: 40px;
    }
    .form-process .first .carousel-text {
        top: 15px!important;
    }
    .text-area {
        margin-right: 0;
    }
    .custom-hit {
        min-height: auto;
    }
    .building {
        width: 45px;
    }

    .voice-recorder {
        width: 335px;
        left: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .play, .delete, .player-stop {
        padding: 14px 45px;
    }

    .actions {
        margin-left: 0;
        bottom: 70px;
        left: 0;
        right: 0;
    }

    .btn-brown {
        padding: 6px 35px 6px 10px;
    }

    .form-process .fourth .form-check:nth-child(odd) {
        margin-right: 0;
    }

    .cordless {
        position: absolute!important;
    }

    .headphones {
        width: 60px!important;
        top: 15px!important;
        right: -10px;
        position: absolute!important;
    }

    .form-process .form-check label {
        width: 100%!important;
    }

    .form-check {
        padding-left: 0;
    }

    .p-75 {
        bottom: 0!important;
        position: relative!important;
        margin: auto;
        display: block;
    }

    /* .form-process .btn-slider {
        position: relative;
        top: -30px;
        left: -20px;
    } */

    .btn-black.with-arrow {
        padding: 4px 16px 4px 16px;
    }

    .contact-form .btn-black {
        margin: auto!important;
        display: block;
    }

    .r-25 {
        right: 50px!important;
    }

    .content-wrapper {
        background-size: cover;
    }

    .road-map .main {
        width: 330px;
        margin: auto;
        display: block;
        margin-top: 200px;
    }

    .bg-img {
        height: 580px;
    }

    /* .set-position {
        position: relative;
        right: -30px;
    } */

    .st-50 {
        top: 0px!important;
    }

    .st-80 {
        top: 0px!important;
    }

    .st-90 {
        top: 0px!important;
    }

    .footer {
        padding-top: 30px;
    }

    .btn-slider {
        top: 40px!important;
        right: 20px;
    }

    .form-process .btn-slider {
        top: -30px!important;
    }

    .carousel-inner {
        overflow: unset;
    }

    .btn.next {
        margin: auto;
        display: block;
    }

    footer {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .social_media {
        padding-left: 0;
        padding-right: 36px;
        background: white;
        padding-bottom: 20px;
    }

    .navbar {
        background-color: white;
    }

    .navbar-nav {
        background-color: white;
    }

    .nav-container {
        padding-right: 0;
    }

    .slick_slider .card img {
        border: 4px solid #E3E9EC;
        /* width: 280px;
        height: 280px; */
        width: 150px;
        height: 150px;
        margin: auto;
        display: block;
        object-fit: contain;
    }

    .top-60 {
        top: 0;
    }

    .slick-slider {
        overflow: auto!important;
        height: auto!important;
    }

    .bg-img.formBg {
        -webkit-clip-path: polygon(90% 0%, 100% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(90% 0%, 100% 0%, 100% 100%, 0% 100%);
        z-index: -1;
    }

    .btn-slider.cross {
        top: 0;
        right: 30px;
    }

    .form-process .third .form-check:nth-child(odd) {
        margin-right: 0;
    }

    .fourth .custom-hit {
        min-height: 850px;
    }

    .play-time {
        margin: 30px 0px;
    }

    .btn-black {
        font-size: 10px;
    }

    .road-map .btn {
        padding: 2px 4px;
    }

    .road-map .btn-black {
        font-size: 8px;
    }

    .arrow-down img {
        width: 7px;
    }
}

@media only screen and (min-width: 550px) and (max-width: 767px) {
    .carousel-text {
        right: 42%!important;
    }
}


@media only screen and (min-width: 1200px) {
    
    .txt-delete {
        left: 290px!important;
    }

    footer {
        /* position: absolute; */
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 20px;
    }

    .form-process .carousel-text {
        bottom: 130px !important;
        position: fixed;
        right: 25% !important;
    }

    .form-process .carousel-indicators {
        position: fixed;
        bottom: 100px!important;
    }
}


@media only screen and (min-width: 1400px) {

    .txt-delete {
        left: 370px!important;
    }

    .actions {
        bottom: 33px!important;
    }

    .p-14 {
        position: absolute!important;
        bottom: 33px!important;
    }

    .box-3 .button {
        top: 15px;
    }

    img.img-fluid.mid-img.rod3 {
        left: 590px;
    }

    img.img-fluid.mid-img.rod2 {
        left: 550px;
    }

    img.img-fluid.mid-img.rod5 {
        left: 440px;
    }

    .main .mid-img.rod1 {
        left: 410px;
    }

    img.img-fluid.mid-img.rod4 {
        left: 480px;
    }

    .box-2 {
        position: absolute;
        bottom: 0;
        left: 260px;
    }

    .box-3 {
        position: absolute;
        top: 100px;
        right: 250px;
    }

    .box-1 {
        position: absolute;
        top: -65px;
        left: 280px;
    }

    /* footer {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        padding-bottom: 20px;
        z-index: 1;
    } */


    /* .btn-slider {
        transform: translate(-60px, 10px);
    } */

    .custom-hit {
        min-height: 530px;
    }

    .carousel-text {
        position: absolute;
        right: 25%;
    }

    /* .form-process .third .carousel-text {
        right: 465px;
    } */
    
}



@media only screen and (min-width: 1420px) {
.form-process .carousel-indicators {
    justify-content: right!important;
    /* margin-right: 23%; */
    width: 150px;
    /* bottom: 160px;
    position: fixed; */
}
.carousel-text {
    position: absolute;
    right: 25% !important;
}

}


@media only screen and (min-width: 1440px) {
      
    .pdf-link {
        bottom: -30px;
        text-align: initial;
        padding-bottom: 20px;
    }

    .r-12 {
        right: 66px!important;
    }

    .form-process .carousel-indicators {
        /* position: fixed;
        bottom: 205px !important; */
        position: fixed;
        bottom: 60px!important;
    }
    
    .form-process .carousel-text {
        /* bottom: 70px !important;
        position: fixed; */
        bottom: 90px !important;
        position: fixed;
    }

    /* .form-process .carousel-indicators {
        position: fixed;
        bottom: 40px !important;
    } */

    div#root {
        overflow: hidden;
    }

    .footer-wrap {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 0!important;
        z-index: 1;
    }
}

@media only screen and (min-width: 1440px) and (min-height: 800px) {
    .form-process .carousel-indicators {
        position: fixed;
        bottom: 150px!important;
    }

    .form-process .carousel-text {
        bottom: 180px !important;
        position: fixed;
    }

    .p-14 {
        position: absolute!important;
        bottom: 16px!important;
    }
}

@media only screen and (min-width: 1440px) and (min-height: 899px) {
    .form-process .carousel-indicators {
        position: fixed;
        bottom: 200px!important;
    }

    .form-process .carousel-text {
        bottom: 230px !important;
        position: fixed;
    }
}

@media only screen and (min-width: 1680px) {

    .r-12 {
        right: -50px!important;
    }
    
    .footer-wrap {
        position: absolute;
    }

    .form-process .carousel-text {
        bottom: 390px!important;
        position: fixed;
    }

    /* .form-process .carousel-indicators {
        position: fixed;
        bottom: 260px !important;
    } */
    .form-process .carousel-indicators {
        position: fixed;
        bottom: 360px!important;
    }
}

@media only screen and (min-width: 1919px) {
    .r-12 {
        right: -170px!important;
    }

    .footer-wrap {
        position: absolute;
    }

    .form-process .carousel-text {
        bottom: 420px!important;
        position: fixed;
    }

    .form-process .carousel-indicators {
        position: fixed;
        bottom: 390px!important;
    }
}

@media only screen and (min-width: 1919px) and (min-height: 1100px) {
    .form-process .carousel-text {
        bottom: 490px !important;
        position: fixed;
    }

    .form-process .carousel-indicators {
        position: fixed;
        bottom: 450px !important;
    }
    
}

@media only screen and (min-width: 1919px) and (min-height: 1199px) {
    .form-process .carousel-text {
        bottom: 540px !important;
        position: fixed;
    }

    .form-process .carousel-indicators {
        position: fixed;
        bottom: 510px!important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 70000px){
    
.form-process .second .carousel-indicators,
.form-process .third .carousel-indicators, .form-process .fourth .carousel-indicators {
    bottom: -7px!important;
    position: absolute;
}

.form-process .second .carousel-text,
.form-process .third .carousel-text, .form-process .fourth .carousel-text {
    bottom: 22px!important;
    position: absolute;
}

.form-process .carousel-text {
    bottom: 42px!important;
    position: absolute;
    right: 18% !important;

}
.form-process .carousel-indicators {
    bottom: 12px!important;
    position: absolute;
    
}
}

.txt-length {
    font-weight: bold;
    color: #101E40;
}

.form-writing .back {
    padding:6px 30px!important;
}

.txt-delete {
    background: #BA4848;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.847' height='15.848' viewBox='0 0 15.847 15.848'%3E%3Cpath id='Path_111' data-name='Path 111' d='M17.054,5.169H13.093V3.846A1.917,1.917,0,0,0,11.112,2H8.735A1.917,1.917,0,0,0,6.754,3.846V5.169H2.792a.792.792,0,1,0,0,1.585h.792v8.716a2.377,2.377,0,0,0,2.377,2.377h7.923a2.377,2.377,0,0,0,2.377-2.377V6.754h.792a.792.792,0,1,0,0-1.585ZM8.339,13.092a.792.792,0,1,1-1.585,0V9.923a.792.792,0,0,1,1.585,0Zm0-9.247c0-.127.166-.261.4-.261h2.377c.23,0,.4.135.4.261V5.169H8.339Zm4.754,9.247a.792.792,0,1,1-1.585,0V9.923a.792.792,0,0,1,1.585,0Z' transform='translate(-2 -1.999)' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px 56px;
    border-radius: 20px;
    box-shadow: 0px 12px 6px rgba(0, 0, 0, 16%);
    left: 370px;
}

.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
  overflow: hidden;
}

.slide-enter {
  transform: translateX(150%);
  overflow: hidden;
}

.slide-enter.slide-enter-active {
  overflow: hidden;
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 100px;
  width: 100%;
  transform: translateX(0%);
  display: none;
}

.slide-exit-active {
  transform: translateX(-100%);
  display: none;
}

.animate {
    animation: animateImage 2s ease-in .2s 1;
    transition: .2s;
}

.animate-fromLeft {
    animation: animate-fromLeft 3s ease-in .3s 1;
    transition: .3s;
}

.animate-fromBottom {
    animation: animate-fromBottom 3s ease-in .3s 1;
    transition: .3s;
}

.st-50 {
    position: relative;
    top: 50px;
}

.st-80 {
    position: relative;
    top: 80px;
}

.st-90 {
    position: relative;
    top: 90px;
}

.color-silver {
    color: #9FA5B2!important;
}

footer p {
    margin-bottom: 0;
    z-index: 1;
    position: relative;
}

.third .next {
    position: relative;
    bottom: 4px;
}

.carousel-item.animate__animated {
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
}

.map-img {
    position: relative;
    z-index: -1;
    width: 800px;
}

.top.dance-text {
     animation: textClock 20s linear 1s infinite;
}

.antiClock.dance-text {
    animation: textAntiClock 20s linear 1s infinite;
}

.dance-text {
    animation: rotateLess 5s infinite ease-in;
    display: inline-block;
}

.bottom-with-50 {
    position: relative;
    bottom: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.navbar-toggle {
    border: none;
    background: transparent !important;
  }
  .navbar-toggle:hover {
    background: transparent !important;
  }
  .navbar-toggle .icon-bar {
    width: 22px;
    transition: all 0.2s;
  }
  .navbar-toggle .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .navbar-toggle .middle-bar {
    opacity: 0;
  }
  .navbar-toggle .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
  .navbar-toggle.collapsed .top-bar {
    transform: rotate(0);
  }
  .navbar-toggle.collapsed .middle-bar {
    opacity: 1;
  }
  .navbar-toggle.collapsed .bottom-bar {
    transform: rotate(0);
  }

  .length-error {
      color: #DD0000;
  }

  .length-error.text {
    position: absolute;
    margin-right: 80px;
  }

@keyframes rotateLess {
    0% {
         transform: translateX(0px);
     }
  
     25% {
        transform: translateX(-5px);
     }
  
     50% {
        transform: translateX(0px);

     }

     75% {
        transform: translateX(5px);
     }
     100% {
        transform: translateX(0px);
     }
  }

@keyframes animateImage {
    0% {
        transform: translateX(150%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes animate-fromLeft {
    0% {
        transform: translateX(-250%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes animate-fromBottom {
    0% {
        transform: translateY(560px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes rotateClock {
    from { 	transform: rotate(0deg) translateX(10px); }
	to   {  transform: rotate(360deg) translateX(10px); }
}

@keyframes rotateAntiClock {
    from { 	transform: rotate(360deg) translateX(10px); }
	to   {  transform: rotate(0deg) translateX(10px); }
}

@keyframes textClock {
    0%{
        transform: translate(0px, 0px);
    }
    10%{
        transform: translate(20px, 0px);
    }
    20%{
        transform: translate(25px, 2px);
    }
    30% {
        transform: translate(30px, 5px);
    }
    40% {
        transform: translate(20px, 5px);
    }
    50% {
        transform: translate(10px, 5px);
    }
    70% {
        transform: translate(10px, 5px);
    }
    90% {
        transform: translate(0px, 5px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}

@media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
        h1, h2, h3::before {position: relative; top: 50px; left: 60px;}

        .btn-black {
            background-color: #101E40;
        }

        [type=button], [type=reset], [type=submit], button {
            -webkit-appearance: none;
            }
        
        footer {
            bottom: 0px;
        }

    }

}

@media screen and (-webkit-min-device-pixel-ratio:0) 
{
::i-block-chrome, h1, h2, h3::before {position: relative; top: 50px; left: 60px;}
}